// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oeuvre-js": () => import("./../../../src/pages/oeuvre.js" /* webpackChunkName: "component---src-pages-oeuvre-js" */),
  "component---src-pages-single-work-js": () => import("./../../../src/pages/single-work.js" /* webpackChunkName: "component---src-pages-single-work-js" */),
  "component---src-pages-ye-olden-stuffe-js": () => import("./../../../src/pages/ye-olden-stuffe.js" /* webpackChunkName: "component---src-pages-ye-olden-stuffe-js" */)
}

